<template>
    <el-dialog v-model="dialogFormVisible" :title="title" @close="closeDialog">
      <el-form :model="dispatchOrdersForm" ref="dispatchOrdersRef" :rules="dispatchOrdersFormRules">
        <el-form-item
        label="维修人员"
        :label-width="formLabelWidth"
        prop="repairManUserId"
        v-model="dispatchOrdersForm.repairManUserId"
      >
        <el-select
          v-model="dispatchOrdersForm.repairManUserId"
          placeholder="请选择维修人员"
          clearable
        >
        <el-option v-for="type in repairManList" :key="type.id" :label="type.userName" :value="type.id"></el-option>

        </el-select>
      </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel()">关闭</el-button>
          <el-button type="primary" @click="save()">保存</el-button>
        </span>
      </template>
    </el-dialog>
  </template>
  
  <script>
   import {ref} from "vue";
    import {httpService} from "@/utils/httpService";
    import {ElMessage, ElForm} from "element-plus";
    import {vxRule} from "@/utils/validator";
  
  export default {
    name: "dispatchOrders",
    components: { ElForm, ElMessage },
    data() {
      return {
        repairManList:[],
        orderId: "",
        dialogFormVisible: ref(false),
        formLabelWidth: "120px",
        title: "",
        personnelType: "",
        dispatchOrdersForm:{
            repairManUserId: ""
        },
        // 校验规则
        dispatchOrdersFormRules: {
            repairManUserId: vxRule(true, null, "blur"),
        },
      };
    },

    mounted() {
        this.getRepairMan();
  },
  watch: {
    $route() {
        this.getRepairMan();
    },
  },
    methods: {
      closeDialog() {
        this.clear();
      },
      cancel() {
        this.dialogFormVisible = false;
        this.clear();
      },
      getRepairMan(){
        httpService("api/sysUser/list/1/1000", { userType: '3' }, "get").then((data) => {
          this.repairManList = data.list;
        });
      },
      clear() {
        this.dialogFormVisible = false;
        //清空form
        this.dispatchOrdersForm.repairManUserId = "";
      },
      save() {
        this.$refs.dispatchOrdersRef.validate((valid) => {
            if (valid) {
                let params = new FormData(); //创建一个form对象,必须是form对象否则后端接受不到数据
                params.append("id",this.orderId);
                params.append("userId",this.dispatchOrdersForm.repairManUserId);
                params.append("status",2);
                    httpService("api/repairInfo/edit", params, "post").then(
                        (data) => {
                            if (data.code == 200) {
                                ElMessage.success("派单成功");
                                this.clear();
                                //刷新父组件的数据
                                this.$parent.getRepairInfoList();
                            } else {
                                ElMessage.error("派单失败");
                            }
                        }
                    );
                
            } else {
                return false;
            }
        });
      },
    },
  };
  </script>
  <style>
  .el-select {
    width: 100%;
  }
  .qr-view {
    display: flex;
  }
  </style>